@import "./ico/btn";
@import "./ico/txt";

.basic-card-ico {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.birthday-phase {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 30px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  .bg {
    height: 100%;
    border-radius: 10px;

    width: 100%;
    object-fit: cover;
    max-height: 100%;
    position: absolute;
  }


  .grid {
    width: 80%;
    justify-content: center;
    //padding: 20px 10px;
    padding-bottom: 50px;

    .col-md {
      width: 33%;
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 124.5%;
      /* or 25px */

      text-align: center;

      color: #FFFFFF;

    }
  }

  .sub-info {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    letter-spacing: 0.3px;

    color: #9A9A9A;
  }

  .content {
    @media screen and (max-width: 545px) {
      * {
        text-align: center;
      }

    }
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .title {
      padding-top: 30px;
      margin-bottom: 28px;
      align-self: center;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 135%;
      /* or 24px */

      letter-spacing: 0.2px;

      color: #FFFFFF;
    }

    .desc {
      margin-top: 20px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 200%;
      /* identical to box height, or 48px */

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      letter-spacing: 0.2px;

      color: #FFFFFF;
    }

    .discount {
      margin-top: 15px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      /* or 22px */

      letter-spacing: 0.2px;

      color: #FFFFFF;

    }

    .infos {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 135%;
      /* identical to box height, or 14px */

      letter-spacing: 0.2px;

      color: #FFFFFF;
    }

    .timer {
      margin-top: 15px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 135%;
      /* identical to box height, or 34px */

      letter-spacing: 0.2px;

      color: #FFFFFF;
    }
  }
}

.progress {
  margin-bottom: 5px;
  background: #D9D9D9;
  height: 2px;
  position: relative;
  width: 100%;

  .overlay {
    position: absolute;
    top: 0;
    z-index: 10;
    background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
    width: 50%;
    height: 2px;
  }

  .overlay-locked {
    position: absolute;
    top: 0;
    z-index: 9;
    background: #D60580;
    //background: linear-gradient(45deg, #D60580 1.02%, #8f567a 97.39%);

    opacity: .5;
    border-radius: 0px 10px 10px 5px;
    width: 50%;
    height: 2px;
  }
}

.w-50 {
  width: 50%;
}

.help-btn {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  background: #282828;
  font-weight: 500;
  border-radius: 100px;
  padding: 15px 30px;
  color: white;
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
}

.tel-ctn {
  &.selected {
    border: 1px solid #00B59C;
  }

  border: 1px solid #E2E8F0;
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  padding: 16px;
  width: 100%;
  letter-spacing: 0.3px;

  /* Greyscale/500 */
  display: flex;
  align-items: center;

  a {
    color: #1A202C;
  }

}

.or {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;

}


.w-70 {
  width: 70%;
}

.top-section {
  padding: 0px 0px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ico-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    margin-bottom: 0 !important;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 40px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Primary/Base */

    background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

.main {
  display: flex;
  justify-content: space-between;
  //height: 100%;
  margin-top: 20px;

  .panel {


    //height: 100%;
    //flex: 1;

    &:first-child {

      width: 67%;
      min-width: 67%;
    }

    &:last-child {
      //margin-left: 1.125rem;
      min-width: 32%;
      width: 32%;
    }

    &.full {
      width: 100%;
    }

    .profil-card {
      height: 100%;

      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 100%;

        img {
          width: 100%;
          border-radius: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.ico-card {
  margin-bottom: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .list-scoll {
    max-height: 20vh;
    overflow: scroll;
  }

  .ligne {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    cursor: pointer;

    .ico {
      width: 48px;
      height: 48px;
      margin-right: 10px;

      img {
        width: 48px;
        height: 48px;
        border-radius: 5px;
      }
    }

    .ctn {
      display: flex;
      flex-direction: column;

      .info {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 21px */

        display: flex;
        align-items: center;
        letter-spacing: 0.3px;

        color: #000000;

      }

      .subinfo {
        font-family: 'Roboto', sans-serif;

        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        /* identical to box height, or 15px */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;

        color: #718096;

      }
    }
  }

  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    margin-top: 13px;

    /* Secondary/Base */

    color: #1A202C;
  }

  .content-card {
    display: flex;
    margin-top: 21px;
    width: 100%;


    .label {
      font-family: 'Roboto', sans-serif;
      padding-top: 9px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;
      justify-content: flex-start;
      letter-spacing: 0.4px;

      /* Secondary/Base */

      color: #1A202C;

    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price-txt {
        font-family: 'Roboto', sans-serif;
        font-style: normal;

        .bold {
          font-family: 'Roboto', sans-serif;
          font-weight: bold;
        }

        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        /* or 24px */

        display: flex;
        align-items: center;
        letter-spacing: 0.2px;

        /* Secondary/Base */

        color: #1A202C;
        @media screen and (max-width: 1500px) {
          flex-direction: column;
        }
        @media screen and (max-width: 992px) {
          flex-direction: row;
        }
      }

      .percent {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 21px */

        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.2px;

        color: #D60580;

      }

    }

    .info {
      img {
        width: 12px;
        height: 12px;
      }
    }

    .ico-ctn-info {
      //display: flex;
      //justify-content: flex-start;
      //align-items: center;
      display: grid;
      grid-template-columns: .5fr 1fr .5fr;
      margin-bottom: 20px;
      gap: 20px;
    }

    .section {
      display: flex;
      flex-direction: column;
      //flex: 1;

      &:first-child {
        //margin-right: 20px;
        //width:70%;
      }

      &:last-child {
        //width: 20%;
      }

      .height-card {
        width: 100%;
        padding: 12px 13px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .top {

          width: 100%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .ico {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            //padding: 8px 10px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 16px;
            }
          }

          .txt {
            margin-top: 9px;
          }
        }

        .price {
          padding-bottom: 22px;
        }
      }


      .sub-card {


        padding: 12px 13px 25px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        //margin-bottom: 12px;
        position: relative;
        height: 100%;
        //width: 33%;

        //&:nth-child(2) {
        //width: 90% !important;
        //}


        //&:first-child {
        //  width: 33%;
        //}
        //
        //&:last-child {
        //  width: 40%;
        //}

        .progress {
          position: absolute;
          bottom: 0;
          left: 0;
          background: #D9D9D9;
          border-radius: 0px 0px 5px 5px;
          width: 100%;
          height: 12px;

          .overlay {
            position: absolute;
            top: 0;
            z-index: 10;
            background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
            border-radius: 0px 10px 10px 5px;
            width: 50%;
            height: 12px;
          }

          .overlay-locked {
            position: absolute;
            top: 0;
            z-index: 9;
            background: #D60580;
            //background: linear-gradient(45deg, #D60580 1.02%, #8f567a 97.39%);

            opacity: .5;
            border-radius: 0px 10px 10px 5px;
            width: 50%;
            height: 12px;
          }
        }

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ico {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            //padding: 8px 10px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 16px;
            }
          }

        }
      }
    }

  }
}

.ico-link {
  color: #D60580;
  font-weight: bold;
}

.divid-horizontal {
  width: 100%;
  height: 1px;
  background: #E2E8F0;;
}


.summit-tokens, .sponsorship {
  margin-top: 20px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  .divid-horizontal {
    margin-top: 15px;
  }

  .top {
    padding: 18px 20px 0px;
    display: flex;
    align-items: center;
    position: relative;

    .logo {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .directoryButton {
        margin-top: 0 !important;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .summit {
        img {
          margin-right: 5px;
        }

        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 18px */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;

        /* Secondary/Base */

        color: #1A202C;
      }

      .token {
        font-family: 'Roboto', sans-serif;

        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        /* or 24px */

        display: flex;
        align-items: center;
        letter-spacing: 0.2px;

        /* Secondary/Base */

        color: #1A202C;


      }
    }
  }

  .ctn {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

  }

  .sponsor-actions {
    padding: 0px 20px 18px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .input-sponsor {
      margin-bottom: 10px;
      padding: 8px 16px;

      span {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* or 21px */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;

        /* Greyscale/700 */

        color: #2A313C;

      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #FAFAFA;
      /* Greyscale / 400 */

      border: 1px solid #CBD5E0;
      border-radius: 5px;

      /* Inside auto layout */

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  .actions {
    padding: 0px 20px 18px 20px;
    margin-top: 12px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: 1400px) {
      .ico-btn {
        margin-bottom: 20px;
      }
    }
    flex-direction: row !important;

    .prices {
      margin-bottom: 21px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1400px) {
        margin-bottom: 5px;
      }

      .label {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 18px */

        letter-spacing: 0.4px;

        /* Secondary/Base */

        color: #1A202C;

      }

      .concern {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        /* or 24px */

        letter-spacing: 0.2px;

        /* Secondary/Base */

        color: #1A202C;

      }
    }
  }
}

.sponsorship {
  .actions {
    padding-bottom: 10px !important;

    .prices {
      &:last-child {
        margin-left: 2.75rem;
      }

      margin-bottom: 0;
    }
  }
}

.string-ligne {


  margin-bottom: 10px;

  &.first {
    margin-top: 34px;
  }

  //&:last-child {
  //  margin-top: 0;
  //}
  &.last {
    margin-bottom: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 0px;
  width: 100%;

  img {
    width: 20px;
    height: 20px;
  }

  .valid {
    margin-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    color: #34A853;

  }
}

.social {
  //display: flex;
  .social-ligne {
    display: grid;
    grid-template-columns: repeat(7, 50px);
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(5, 50px);

    }
    //justify-content: space-between;
    gap: 12px;
    //align-items: center;
    width: 100%;
  }

  //@media screen and (max-width: 1500px) {
  //  flex-direction: column;
  //  .social-ligne {
  //    justify-content: space-between;
  //    align-items: center;
  //    width: 100%;
  //  }
  //  .divided {
  //    opacity: 0;
  //    //margin: 30px 0px;
  //  }
  //}

  .icoafter {
    a {
      margin-right: 12px;

      &:last-child {
        //margin-right: 0;
      }
    }

    img {
      height: 24px;
      @media screen and (max-width: 1500px) {
        height: 20px;
      }
    }
  }

  .ico {
    a {
      margin-right: 12px;

      &:last-child {
        //margin-right: 0;
      }
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.subline {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 15px */

  display: flex;
  align-items: center;
  letter-spacing: 0.4px;

  color: #718096;
}

.ico-subtitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: 0.2px;

  color: #1A202C;
}

.divided {
  width: 1px;
  height: 34px;
  background: #E2E8F0;
  margin: 10px 24px;
}


.price-ico-card {
  &.end {
    * {
      color: #A0AEC0 !important;

      button span {
        color: white !important;
      }

      svg {
        path {
          stroke: #A0AEC0 !important;
        }
      }
    }

    .progress, .overlay {
      background: #A0AEC0 !important;
    }
  }

  margin-right: 32px;

  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 11px 10px;


  .price {
    justify-content: flex-start;
    margin-bottom: 22px;
  }


  .phase {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
    /* Secondary/Base */

    color: #1A202C;
  }


  .info {
    display: flex;
    flex-direction: column;

    .cliff {
      margin-bottom: 2px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;
      letter-spacing: 0.4px;

      color: #D60580;

    }

    .count {
      margin-bottom: 10px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 11.5px;
      line-height: 15px;
      /* identical to box height, or 15px */

      display: flex;
      align-items: center;
      letter-spacing: 0.4px;

      color: #718096;

    }
  }
}


/********************** INVEST PAGE *********************/
.invest-panel {
  .ico-card {
    padding-bottom: 80px;
  }
}

.invest-centered {
  width: 90%;
  margin: 0 auto;
  margin-top: 55px;

  @media screen and (min-width: 1500px) {
    width: 70%;
  }

  .title {
    display: flex;
    align-items: flex-start;
    margin: 30px 0px 13px 0px;

    .ico-h6 {
      margin-bottom: 0 !important;
    }
  }

  .buying-card {
    padding: 9px 14px;
    display: flex;
    flex-direction: column;

    .input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      input {
        padding: 0px;
        width: 100%;
        margin-bottom: 0px;
        border: none;
        background: transparent;

        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        /* or 21px */

        display: flex;
        align-items: center;
        letter-spacing: 0.2px;

        color: #282828;

        &::placeholder {

          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          /* or 21px */

          display: flex;
          align-items: center;
          letter-spacing: 0.2px;

          color: #282828;
        }
      }
    }

    .label {
      margin-bottom: 12px;
    }

    .input {
      margin-bottom: 9px;
    }
  }

  .max {
    margin-left: 14px;
    margin-top: 12px;
  }
}

/********************** ROADMAP PAGE *********************/
.roadmap {
  //background: black;
  .content-card {
    position: relative;

    .progress {
      height: 20px;
      //width: 100%;
      top: 27%;
      .overlay {
        //width: 100%;
        height: 20px;

        background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
        border-radius: 100px;
      }
    }

    .roadmap {
      position: relative;
      //margin-top: 88px;
      padding-bottom: 65px;
      padding-left: 40px;
      overflow-x: scroll;
      overflow-y: hidden;
      display: flex;


      //&::-webkit-scrollbar {
      //  background: #BBD6F5 !important;
      //  height: 8px !important;
      //  margin-left: 40px !important;
      //  border-radius: 100px !important;
      //}
      //
      ///* Track */
      //&::-webkit-scrollbar-track {
      //  background: #E8F1FB !important;
      //
      //  border-radius: 100px !important;
      //
      //}
      //
      ///* Handle */
      //&::-webkit-scrollbar-thumb {
      //  background: #BBD6F5 !important;
      //  border-radius: 100px !important;
      //
      //}
      //
      ///* Handle on hover */
      //&::-webkit-scrollbar-thumb:hover {
      //  background: #BBD6F5 !important;
      //  border-radius: 100px !important;
      //}


      .road {
        margin-right: 155px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &.disabled {
          .overlay {
            display: none;
          }

          .round {
            background: #E8F1FB;
            border: 5px solid #E8F1FB;
          }

          .phase, .sub {
            color: #BBD6F5;
            background: none;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
            background-clip: unset;

          }

          .round {
            svg {
              path {
                fill: #BBD6F5
              }
            }
          }
        }

        .overlay {
          position: absolute;
          width: 60px;
          top: 37px;
          height: 60px;
          border-radius: 100px;
          z-index: 1;
          background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
        }

        .round {
          &.bg-white {
            margin-top: 10px;
            background: white;
            z-index: 2;
            width: 50px;
            height: 50px;
          }

          background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
          border: 5px solid #FFFFFF;
          box-sizing: border-box;
          box-shadow: 0px 6px 12px rgba(50, 73, 100, 0.1);
          border-radius: 100px;
          display: flex;
          align-items: center;
          width: 60px;
          height: 60px;
          margin-bottom: 10px;

          img {
            width: 24px;
            height: 24px;
          }

          justify-content: center;
        }

        .phase {
          font-family: 'Roboto', sans-serif;
          font-style: italic;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          display: flex;
          align-items: flex-end;
          text-align: center;
          margin-bottom: 9px;
          background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .sub {
          &:first-child {
            width: 120px;
          }

          width: 210px;
          font-family: 'Roboto', sans-serif;
          margin-top: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          /* or 133% */

          text-align: center;

          &.margin {
            // margin-top: 10px;
          }

          /* Black */

          color: #192533;

        }
      }
    }
  }
}

/********************** TRANSACTIONS *********************/
.transactions {
  .basic-card-ico {
    padding: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

///********************** TRANSACTIONS *********************/
.stacking {
  .info {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #000000;
    margin-bottom: 7px;
    margin-top: 21px;
  }

  .content {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #000000;
    margin-bottom: 43px;
  }

  .stackings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 525px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;

    }
    //justify-content: space-around;
    //align-items: flex-start;
    //flex-wrap: wrap;

    .basic-card-ico {
      //width: 350px;
      @media screen and (max-width: 525px) {
        width: 100%;
      }
      //margin: 5px;
      display: flex;

      flex-direction: column;
      align-items: flex-start;
      padding: 12px 23px 20px;

      .top {
        margin-bottom: 12px
      }

      .lists {
        margin-top: 25px;
        display: flex;
        flex-direction: column;

        .lign {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          gap: 11px;
          //img {
          //  margin-right: 11px;
          //}

          span {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            //line-height: 36px;
            display: flex;
            align-items: center;

            color: #718096;

            &.valid {
              color: #34A853;
              font-weight: bold;
            }
          }
        }
      }

      .bottom {
        //margin-top: 37px;
        display: flex;
        flex-direction: column;

        .sub-line{

        }

        .percent {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.2px;
          color: #D60580;
        }

        .btn {
          margin-top: 31px;
        }
      }
    }

  }
  .small-txt {
    font-size: 12px;
    color: #D60580;
  }
}


/********************** SKELETON *********************/

