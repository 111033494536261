@font-face {
  font-family: 'Montserrat-Regular';
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: 'OpenSans-Medium';
  src: url("../fonts/OpenSans-Medium.ttf");
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url("../fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-BoldItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-BlackItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraBold.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Bold.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ExtraLight.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Black.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Thin.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Italic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Regular.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-ThinItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-MediumItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Medium.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Light.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-LightItalic.woff2') format('woff2'),
  url('../fonts/poppins/Poppins-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Italic.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Black.woff2') format('woff2'),
  url('../fonts/roboto/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}





