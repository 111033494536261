@import "./../variables";
.btn-details {
  &:hover{
    background: transparent !important;
    border: 1px solid #EDF2F7 !important;
    color: #1A202C !important;
  }
  &.cancel{
    color: #D80027;
    border: 1px solid #D80027;
    &:hover{
      color: #D80027 !important;
      border: 1px solid #D80027 !important;
    }
  }
  font-family: 'Roboto', sans-serif;
  background: transparent;
  //width: 96px;
  height: 40px;
  padding: 0px 29px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EDF2F7;
  border-radius: 8px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  span {
    flex: auto !important;
  }

  text-align: right;
  letter-spacing: 0.2px;

  /* Greyscale / 900 */

  color: #1A202C;
}
.ico-btn {
  border: none;
  cursor: pointer;
  background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
  border-radius: 5px;
  padding: 16px 27px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.4px;
  /* Others/White */

  color: $white;


  transition: .3s;

  &.size {
    font-size: 16px;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
    transition: .3s;
  }

  &.disabled {
    cursor: auto;
    pointer-events: none;
    background: #A0AEC0;
  }


  &.outline {
    width: 100%;
    background: transparent;
    border: 1px solid #D60580;
    color: #D60580;
    transition: .3s;

    span {

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.2px;
      color: #E043A0 !important;
    }

    &:hover {
      border: 1px solid #E043A0 !important;
      color: rgba(#D60580, 0.75);
      transition: .3s;

      span {
        color: #D60580 !important;
      }
    }

  }
}

.overlay-btn {
  position: absolute;
  z-index: 0;
  top: -4%;
  left: -1.5%;
  width: 103%;
  height: 108%;
  border-radius: 5px;
  background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
}
