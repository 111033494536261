$mining-black: #0000F5;
$mining-primary-color: #0000F5;
$mining-linear-gradient: linear-gradient(71deg, #0000FF 14%, #63E5FF 85%);
$mining-linear-gradient-hover: linear-gradient(45deg, #00F 14.24%, #63E5FF 85.5%) !important;
$mining-orange: #E09B3D;
$mining-red: #C74C4D;
$mining-green: #34A853;
$mining-grey: #718096;
$mining-light-grey: #C0C0C0;
$mining-offer-border: #E6E9F5;

$mining-status-waiting: #FF784B;
$mining-status-bg-waiting: #FFF7F5;

$mining-status-success: #22C55E;
$mining-status-bg-success: #F6FDF9;

$mining-status-error: #FF4747;
$mining-status-bg-error: #FFF5F5;

$ico-linear-gradient: linear-gradient(45deg, #D60580, #00FFFF);
