.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 1rem);
  justify-content: space-between;
  //padding: 6rem 0.5rem 0.5rem 0.5rem;
  transition: margin-left $transitionDuration;
}

.layout-wrapper-bg {
  background: linear-gradient(45deg, rgba(214, 5, 128, 0.2) 1.02%, rgba(0, 255, 255, 0.2) 97.39%), #FFFFFF;
}

.layout-main {
  flex: 1 1 auto;
  background-color: #fafafa;
  border-radius: 12px;
  padding: 20px;
}

