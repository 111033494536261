/* General */
$fontSize: 10px;
$borderRadius: 12px;
$transitionDuration: .2s;


/* COLOR */
$white: #FFFFFF;


$dark-bg-global: #001A1A;
$dark-body-bg: linear-gradient(45deg, rgba(214, 5, 128, 0.5) 1.02%, rgba(0, 255, 255, 0.5) 97.39%), #000000;
$dark-bg-card: #002626;
$dark-bg-subcard: #003333;
$dark-bg-input: #002626;
