$research-black: #1A202C;
$research-primary-color: #6D34DB;
$research-linear-gradient: linear-gradient(45deg, #6D34DB 0%, #C79CCE 100%);
$research-base: #C79CCE;
$research-linear-gradient-hover: linear-gradient(71deg, #6D34DB 15%, #C79CCE 83%) !important;
$research-orange: #E09B3D;
$research-red: #C74C4D;
$research-green: #34A853;
$research-grey: #718096;
$research-light-grey: #C0C0C0;
$research-offer-border: #E6E9F5;

$research-status-waiting: #FF784B;
$research-status-bg-waiting: #FFF7F5;

$research-status-success: #22C55E;
$research-status-bg-success: #F6FDF9;

$research-status-error: #FF4747;
$research-status-bg-error: #FFF5F5;

$ico-linear-gradient: linear-gradient(45deg, #D60580, #00FFFF);
