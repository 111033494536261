* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  //background-color: var(--surface-ground);
  background: linear-gradient(71.27deg, rgba(214, 5, 128, 0.2) 1.02%, rgba(0, 255, 255, 0.2) 97.39%), #FFFFFF;

  margin: 0;
  padding: 0;
  // min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.research {
    background: linear-gradient(45deg, rgba(109, 52, 219, 0.2) 0%, rgba(199, 156, 206, 0.2) 100%), #FFFFFF;
  }

  &.mining{
    background: linear-gradient(71deg, rgba(#63E5FF33, 0.2) 0%, rgba(199, 156, 206, 0.2) 100%), #FFFFFF;
  }

  &.integrate {
    background: white !important;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.layout-theme-light {
  background-color: #edf1f5;
}

body.p-overflow-hidden {
  overflow: auto !important;
}

body.research.p-overflow-hidden {
  overflow: hidden !important;
}

