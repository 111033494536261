/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import 'material-icons/iconfont/material-icons.css';
@import "assets/sass/layout/_variables";
@import "assets/sass/layout/_layout";
@import "assets/sass/overrides/_overrides";
@import "assets/sass/flag.scss";
@import "assets/sass/modal.scss";
@import './assets/sass/fonts';
@import 'assets/sass/responsive';

.no-rotate{
  transform: rotate(0deg) !important;
}
::ng-deep {
  .no-rotate{
    transform: rotate(0deg) !important;
  }
  canvas {
    z-index: 10000000 !important;
  }

}

.airdropmodal {
  .p-dialog-content {
      min-width: 680px;
    @media screen and (max-width: 992px) {
      min-width: auto !important;
    }
  }
}

canvas {
  z-index: 10000000 !important;
}

.phoneModal {
  .p-dialog-content {
    overflow: visible !important;
  }
}

.cguModal {
  .p-dialog-content {

    @media screen and (max-width: 525px) {
      max-width: 100% !important;
      min-width: 100% !important;
      .errorDisplay {
        padding: 30px !important;

        .title {
          font-size: 22px !important;
        }
      }
      .actions {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px;
      }
    }
  }
}


//}

.cguModal {
  .p-dialog-content {

    @media screen and (max-width: 525px) {
      max-width: 100% !important;
      min-width: 100% !important;
      .errorDisplay {
        padding: 30px !important;

        .title {
          font-size: 22px !important;
        }
      }
      .actions {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px;
      }
    }
  }
}

_::-webkit-full-page-media, _:future, :root .safari_only {
  .directory-list {
    @media screen and (max-width: 747px) {
      justify-content: flex-start !important;

    }
  }

}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

li {
  &:focus-visible {
    outline: none !important;
  }

  a {
    &:focus-visible {
      outline: none !important;
      outline-offset: unset !important;
    }
  }
}

input {
  &:focus-visible {
    outline: none !important;
    box-shadow: unset !important;
  }

  &:focus {
    box-shadow: unset !important;
    //border: none !important;
    outline: none !important;

  }
}

body.profile {
  .p-card, .main.full {
    min-height: calc(100vh - (110px + 1rem)) !important;
  }
}

.p-card, .main.full {
  .ico-card {
    min-height: 100%;
  }

  min-height: calc(100vh - (100px + 1rem));
}

//button, select, textarea {
//  &:focus-visible {
//    outline: none !important;
//  }
//
//  &.gradient-button, &.p-dialog-footer {
//    &:active {
//      background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%) !important;
//    }
//  }
//
//  &:active {
//    background: transparent;
//  }
//
//  &:hover {
//    border-color: #D60580 !important;
//  }
//
//  &:focus {
//    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #D60580, 0 1px 2px 0 black !important;
//    border-color: #D60580 !important;
//  }
//}

.layout-menu {
  ion-icon {
    min-width: 22px;
  }
}

ion-icon {
  font-size: 22px;

  title {
    display: none;
  }


}

.ico-info-tooltip {
  max-width: 25rem;
}

.summit-logo {
  width: 30px;
  height: 30px;
}

p-password {
  .pi {
    right: 50px;
  }
}

.required {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1px;
  /* identical to box height, or 24px */
  position: absolute;
  right: 10px;
  top: 5px;
  bottom: 0;
  display: flex;
  align-items: center;

  /* Greyscale/500 */

  color: red;

}

:root {
  --desktop-max-width: 992px;
  --main-font-family: "Roboto";
  --main-font-family-bold: "Roboto-Bold";
  --brand-color: #ff9900;
  --quantum-color: #e4194d;
  --impulse-color: #f79507;
  --main-background-color-gradient-start: #D60580;
  --main-background-color-gradient-end: #00FFFF;
  --main-background-color-gradient-start-transparent: #d6057f28;
  --main-background-color-gradient-end-transparent: #00ffff28;
  --menu-header-font-size: 22px;
  --menu-list-background: #fff;
  --menu-list-font-size: 12px;
  --custom-header-height: 100px;
  --custom-header-background-start: #1A202C;
  --custom-header-background-end: #1A202C;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  height: 100%;
  @media screen and (max-width: 525px) {
    min-height: auto;
    height: auto;
  }

  &.fireworks {
    overflow: hidden !important;
  }
}

@media screen and (max-width: 525px) {
  .userSettings {
    width: 100% !important;

    .changeUserPasswordBox {
      flex-direction: column;
    }

    .actions {
      flex-direction: column;

      app-ico-btn {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  .p-toast {
    width: 20rem !important;

    .p-toast-message .p-toast-message-content {
      .p-toast-message-icon {
        font-size: 1.5rem;
      }

      .p-toast-summary {
        font-size: 18px;
      }

      .p-toast-detail {
        font-size: 14px;
      }
    }
  }
}

.back {

}

.overlay-right {
  left: unset !important;
  right: 0 !important;
  width: fit-content !important;
}

.p-toast-top-right {
  top: 110px !important;
  right: 20px;
}

.dashboard .p-toast-top-right {
  top: 20px !important;
}

.main-text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  /* identical to box height, or 40px */

  text-align: center;
  letter-spacing: 0.2px;

  /* Greyscale/900 */

  color: #1A202C;
}

.gradient-text {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  // margin-bottom: 16px;
  background: -webkit-linear-gradient(71deg, var(--main-background-color-gradient-start) 1%, var(--main-background-color-gradient-end) 97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-cs {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  // margin-bottom: 16px;
  background: -webkit-linear-gradient(71deg, var(--main-background-color-gradient-start) 1%, var(--main-background-color-gradient-end) 97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1385px) {
  .gradient-text-cs {
    font-size: 20px;
  }
}

.birthday-price {
  background: -webkit-linear-gradient(45deg, #D60580, #00FFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px !important;
}

.birthday-percent {
  background: -webkit-linear-gradient(45deg, #D60580, #00FFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px !important;
}

.layout-menu .ion::before, .layout-menuitem-icon::before {
  font-size: 22px;
  width: 24px;
  height: 24px;
}

.layout-menu .ion::before {
  display: grid;
  place-items: center;
}

.p-toast {
  top: 50px;
  z-index: 99999999999;
}

.p-password .p-inputtext {
  width: 100% !important;
  padding: 1rem !important;
}

.modalLangue {
  .p-dialog-footer {
    opacity: 0;
    display: none;
  }
}

.neutral-button {
  background: #CCCCCC;
  color: #ffffff;
  border: none;
}

.neutral-button:hover {
  background: #CCCCCC;
  background-color: #CCCCCC;
}

.border-gradient {
  border: 2px solid transparent;
  border-radius: 12px;
  background-image: linear-gradient(white, white), linear-gradient(to right, red, orange);
  background-origin: border-box;
  background-clip: content-box, border-box;

  .p-button-label {
    background: -webkit-linear-gradient(71deg, var(--main-background-color-gradient-start) 1%, var(--main-background-color-gradient-end) 97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.object-cover {
  object-fit: cover;
}

.object-container {
  object-fit: contain;
}

.countdown-birthday {
  font-size: 14px;
  margin-right: 3px;

  &.no-m {
    margin-right: 0 !important;
  }
}

.display-block {
  display: block !important;
}

.confirm-dialog-accept {
  border: none;
  cursor: pointer;
  background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
  border-radius: 5px;
  padding: 11px 27px;
  font-family: 'Roboto-Bold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.4px;
  cursor: pointer;
  /* Others/White */

  color: $white;
}

.confirm-dialog-reject {
  border-radius: 5px;
  padding: 11px 27px;
  font-family: 'Roboto-Bold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.4px;
  cursor: pointer;
  /* Others/White */

  background: transparent;
  border: 1px solid #D60580;
  color: #D60580;
  transition: .3s;
}

.pointer-none {
  pointer-events: none;
}

.p-dialog {
  //padding: 40px !important;
  box-shadow: 0px 16px 24px rgba(93, 106, 131, 0.02) !important;
  border-radius: 16px !important;

  .p-dialog-header {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    padding-top: 40px;
    position: relative;
    justify-content: center !important;
    padding-right: 0 !important;
    padding-left: 0 !important;

    button {
      &:focus {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #D60580, 0 1px 2px 0 black !important;
      }
    }

    .p-dialog-title {
      background: -webkit-linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px !important;
      line-height: 40px;

      text-align: center;
      letter-spacing: 0.2px;

      color: #1A202C;
    }

    .p-dialog-header-icon {
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
    }

  }

  .p-dialog-footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 40px !important;

    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;

    button {
      width: 40%;
      margin-right: 0;

      &:focus-visible, &:focus, &:focus-within {
        outline: none !important;
        box-shadow: none !important;
      }

      &:active {
        background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
      }

      &:first-child {

        background: transparent !important;
        border: 1px solid #D60580 !important;
        color: #D60580 !important;


      }

      &:hover {
        background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
      }

      span {
        &.pi {
          display: none;
        }

        &.p-button-label {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 150%;
        }
      }

      border: none;
      cursor: pointer;
      background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
      border-radius: 5px;
      padding: 11px 27px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: 0.4px;
      cursor: pointer;
      /* Others/White */

      color: $white;

    }
  }

  .p-dialog-content {

    padding-left: 40px;
    padding-right: 40px;


    @media screen and (max-width: 525px) {
      padding-left: 0;
      padding-right: 0;
    }

    .p-confirm-dialog-message {
      /* body/large/medium */

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px !important;
      line-height: 150%;
      /* or 24px */

      text-align: center;
      letter-spacing: 0.2px;

      /* Greyscale/600 */

      color: #718096;

    }

    //i {
    //  display: none !important;
    //}
  }
}

.stripe-box {
  background-color: blue;

  .StripeElement {

  }

  //  *{
  //    font-weight: bold;
  //  }
}

.research {
  .p-dialog {

    @media screen and (max-width: 992px) {
      .p-dialog-content {

        min-width: 400px !important;
      }
    }
    @media screen and (max-width: 450px) {
      .p-dialog-content {

        min-width: 300px !important;
      }
    }

    .p-dialog-content {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0 !important;
    }
  }
}

.mini-loader {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 1px solid #D60580;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1.0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.break-word {
  word-break: break-word;
}

app-login,
app-import,
app-forgot,
app-reset-password,
app-register,
app-verify-email, app-discord {

  app-ico-btn {
    button {

      font-size: 16px !important;

      span {
        font-size: 16px !important;
      }
    }
  }

  .no-account {
    /* body/large/medium */
    margin-top: 34px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    letter-spacing: 0.2px;

    /* Greyscale/900 */

    color: #1A202C;

    b {
      cursor: pointer;
    }
  }

  .form-error {
    color: red;
    font-size: small;
    text-align: left;
    width: 83%;
    margin: 0 8.5%;

    &.full {
      width: 100%;
      margin: 0 !important;
    }
  }

  .form-block {

    margin: 20px 0;
    width: 650px;
    max-width: 90vw;
    background: white;
    position: relative;
    border-radius: 16px;
    padding: 40px;

    .p-checkbox {
      margin-right: 0 !important;
    }

    .p-checkbox-box {
      border-radius: 100%;
      border: 1px solid #E2E8F0;

    }

    .p-checkbox-label {
      margin-left: 12px !important;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      letter-spacing: 0.2px;

      /* Greyscale/900 */

      color: #1A202C;

    }

    input {
      /* Greyscale/300 */
      background: transparent;
      border: 1px solid #E2E8F0;
      border-radius: 10px;

      padding: 16px;

      &::placeholder {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        display: flex;
        align-items: center;
        letter-spacing: 0.3px;

        /* Greyscale/500 */

        color: #A0AEC0;
      }
    }


    .lists {
      display: flex;
      flex-direction: column;
      margin-top: 52px;

      .ligne {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        span {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 300%;
          /* or 42px */

          letter-spacing: 0.3px;

          color: #000000;
        }

      }
    }

    .content {


      .title {
        position: relative;

        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;

        color: #282828;

      }
    }

    .top-container {
      margin-bottom: 30px;
      text-align: center;

      .main-text {
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
      }

      .sub-text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;
        letter-spacing: 0.3px;

        /* Greyscale/600 */

        color: #718096;

      }
    }

    .form-container {
      width: 100%;
      //max-width: 500px;
      margin: 0 auto;
    }

    .title {
      text-align: center;
      font-size: 20px;
    }

    .password-errors {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 20px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .actions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      flex: 1;
    }
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .description {
    /* body/large/medium */
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center !important;
    /* or 24px */
    a {
      color: rgba(214, 5, 128, 1);

    }

    letter-spacing: 0.2px;

    /* Greyscale/600 */

    color: #718096;

  }

  .otp {

    .txt {
      margin-top: 40px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.2px;

      /* Greyscale/900 */

      color: #1A202C;

    }

    input:not(code-input input) {
      border: 1px solid #E2E8F0 !important;
      border-radius: 12px !important;
      //margin-right: 20px !important;

      &:last-child {
        margin-right: 0;
      }

      &:focus-visible {
        outline: #D60580 !important;
        border-color: #D60580 !important;
      }

      width: 80% !important;
      height: 70px !important;
      //padding: 12px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      /* Heading/H4 */

      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      /* or 31px */

      /* Greyscale/900 */

      color: #1A202C;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      @media screen and (max-width: 545px) {
        width: 100% !important;
        height: 50px !important;
        padding: unset !important;
      }

    }
  }

  .users {
    margin-top: 40px;
    padding: 30px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &.error {
      justify-content: space-between;

    }

    img {
      &.error {
        width: 20px;
        height: 20px;
      }

      margin-right: 10px;
      width: 40px;
      height: 40px;
    }

    .ctn {
      display: flex;
      flex-direction: column;

      .summitgh {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;

        /* Secondary/Base */

        color: #1A202C;
      }

      .user {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 135%;
        /* or 24px */

        display: flex;
        align-items: center;
        letter-spacing: 0.2px;

        /* Secondary/Base */

        color: #1A202C;

      }
    }
  }
}

.p-dynamic-dialog {
  max-width: 95vw !important;
  max-height: 95vh !important;
}

.p-sortable-column-icon {
  color: #718096 !important;
}


.userSettings {

  padding: 0px 5%;
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0px 10px;
  }

  .changeUserPasswordBox {
    margin-top: 42px;

    .showPassword {
      position: absolute;
      right: 15px;
      bottom: 0;
      top: 0;
      //top: -90px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .medium-txt {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      /* or 21px */

      letter-spacing: 0.3px;

      a {
        /* body/medium/regular */

        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        letter-spacing: 0.3px;

        color: #E043A0;
      }

      color: #282828;
    }

  }

  .text-base {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* Neutrals/2 */

    color: #23262F;
  }

  .form-group {
    margin-bottom: 20px;

    &.id-group {
      margin-bottom: 35px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    display: flex;
  }

  .form {
    margin-top: 20px;
    @media screen and (max-width: 525px) {
      .form-group {
        flex-direction: column;

        input {
          margin-right: 0 !important;
          margin-bottom: 17px;


          &.tel {
            margin-bottom: 0px;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .birthday-input {
    width: 100%;
    flex: 1;
  }

  input, .birthday-input input {
    flex: 1;

    /* Greyscale/300 */
    background: transparent;
    border: 1px solid #E2E8F0;
    border-radius: 10px;

    padding: 16px;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      letter-spacing: 0.3px;

      /* Greyscale/500 */

      color: #A0AEC0;
    }

    &.disabled {
      background: #D9D9D9;
    }

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    /* Greyscale/700 */

    color: #2A313C;
  }


  .p-dropdown, .p-multiselect {
    border: unset !important;
    background: #F4F5F6;
    border-radius: 5px;
    padding: 12px 16px;
    padding-right: 0;

    .p-dropdown-label {
      padding: unset;
      margin-right: 115px;
    }
  }

  .country-item {
    margin: 0;

    img {
      margin-right: 12px;
    }

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: right;

    /* Neutrals/2 */

    color: #23262F;
  }


  .avatar {
    transition: .4s;
    //cursor: pointer;

    .edit {
      border-radius: 100%;
      background: white;
      position: absolute;
      z-index: 10;
      top: 10px;
      right: 10px;
      padding: 5px;
    }

    &:hover {
      .overlay {
        opacity: 1;
        transition: .4s;
      }
    }

    img {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      object-fit: cover;
      //cursor: pointer;
    }

    position: relative;

    .overlay {
      opacity: 0;
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 100%;
      background: rgba(black, .4);
      transition: .4s;

    }
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */


    /* Neutrals/2 */

    color: #23262F;

  }

  .medium-txt {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    letter-spacing: 0.3px;

    /* Greyscale/600 */

    color: #718096;

  }

}


//body * {
//  transition: .4s !important;
//}

.cursor-normal {
  cursor: initial !important;
}

.cursor {
  cursor: pointer !important;
}

.border-round-full {
  border-radius: 100%;

}

.border-round-xl {
  //border-radius: 60rem !important;
}

*::-webkit-scrollbar {
//  background-color: #fff;
//  width: 10px;
//  height: 10px;
  display: none;
}
//
///* background of the scrollbar except button or resizer */
//*::-webkit-scrollbar-track {
//  background-color: #fff;
//}
//
///* scrollbar itself */
//*::-webkit-scrollbar-thumb {
//  background-color: #babac0;
//  border-radius: 16px;
//  border: none !important;
//}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

body.dark {


  transition: .4s !important;

  &::-webkit-scrollbar, &::-webkit-scrollbar-track, {
    background: linear-gradient(45deg, rgba(214, 5, 128, 0.5) 1.02%, rgba(0, 255, 255, 0.5) 97.39%), #000000;
  }

  *::-webkit-scrollbar {
    background-color: transparent !important;

  }

  /* background of the scrollbar except button or resizer */
  *::-webkit-scrollbar-track {
    background-color: transparent !important;
  }


  &::-webkit-scrollbar-button, *::-webkit-scrollbar-button, &::-webkit-scrollbar-corner, *::-webkit-scrollbar-corner {
    display: none;
  }

  .userButton .p-button-icon {
    color: white;
  }

  .p-card, .main.full {
    background: $dark-bg-card;
  }

  input {
    color: white !important;
  }


  app-help {
    .main-title,
    .top-title,
    .title,
    .description,
    .subtitle,
    .popular-search-label {
      color: #fff !important;
      -webkit-text-fill-color: #fff !important;
    }

    .question-search-container {
      background: #525252 !important;
    }
  }

  .sun, .moon {
    color: white;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }

  .p-button-icon.pi.pi-moon {
    color: white !important;
  }

  .p-dialog-header, .p-dialog-footer {
    background: $dark-bg-subcard;
  }

  .transactionDisplay {
    .modal-header {
      .title {
        color: white !important;
      }

      color: white !important;
    }

    .modal-footer {
      color: white !important;
    }
  }

  .modal-header {
    * {
      color: white !important;
    }
  }

  .modal-footer {
    color: white !important;
  }

  .p-dialog {
    .p-dialog-content {

      background: $dark-bg-subcard;
    }
  }

  input {
    &::placeholder {
      color: #C0C0C0 !important;
    }
  }

  .value {
    color: #C0C0C0 !important;

  }

  //* {

  //}


  background: $dark-body-bg;

  .wrapper {
    background: $dark-body-bg;
  }

  .footer-txt {
    color: #C0C0C0 !important;
  }

  .form-block {
    background: $dark-bg-card;

    .title {
      color: white
    }

    .label, .sub-text {
      color: #C0C0C0 !important;
    }

    * {
      color: white;
    }
  }


  .individual-company {
    .label {
      color: white !important;
    }
  }

  .link {
    &:hover {
      text-decoration: underline white;
    }
  }

  .info {
    svg {
      path {
        stroke: white;
        color: white;
      }
    }
  }

  .layout-menu li .layout-menuitem-root-text {
    color: white;
  }


  .layout-menu li a:hover {
    background: transparent !important;
    color: #C0C0C0;

    span {
      color: #C0C0C0;
    }
  }

  .layout-menu li a.active:hover {
    background: linear-gradient(75.72deg, #D60580 -35.18%, #00FFFF 80.96%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
  }

  .layout-menu li a span {
    color: #C0C0C0;
  }

  .layout-menu li ul ion-icon {
    color: #C0C0C0;
  }

  .pi-sun, .pi-globe, .pi-info-circle {
    &::before {
      color: white;
    }
  }

  .p-card .p-card-body, .surface-section {
    background: $dark-bg-card !important;
  }

  .header-body {
    .code {
      color: white !important;
    }
  }

  .userSettings {
    input {
      color: white;

      &::placeholder {
        color: white;
      }
    }

    .title {
      color: white !important;
    }

    .referralReward {
      color: white !important;

    }

    .medium-txt {
      color: #C0C0C0 !important;
    }

    .referral-box {
      background: $dark-bg-subcard !important;

      * {
        color: white !important;
      }
    }
  }

  .ico-btn {
    &.outline {
      background: $dark-bg-subcard;
    }
  }

  .transactionDisplay .modal-body .lineValue .txLabel, .transactionDisplay .modal-body .lineValue .txValue {
    color: white !important;
  }

  .search {
    svg {
      circle, path {
        stroke: white;
      }
    }
  }


  .p-datatable .p-paginator-bottom {
    background: transparent;

    .p-dropdown-panel {
      background: $dark-bg-card;
      color: white;

      .p-dropdown-items .p-dropdown-item {
        &:hover {
          background: $dark-bg-subcard;
          color: white;
        }

        &.p-highlight {
          background: $dark-bg-subcard;
          color: white;
        }
      }
    }

    .p-dropdown {
      background: transparent;

      .p-dropdown-label {
        color: white;
      }
    }

    .p-paginator-first, .p-paginator-last, .p-paginator-prev, .p-paginator-next {
      color: white;

    }

    .p-paginator-page {
      &.p-highlight {
        color: black;
      }

      color: white;
    }
  }

  .btn-details {
    color: white !important;

    &:hover {
      color: white !important;
    }
  }

  .layout-main, .layout-sidebar {
    transition: .4s !important;
    background: $dark-bg-global;
  }

  .ico-card {
    transition: .4s !important;
    background: $dark-bg-card;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  }

  .ico-subtitle, .ico-h6, .ico-h6-price, .medium-txt {
    color: white !important;
  }

  .xmall-txt, .count, .small-txt {
    color: #C0C0C0 !important;
  }

  .ico-p {
    color: #C0C0C0 !important;

  }

  .transactionRow {
    .title, .date-txt, .basic-txt {
      color: white !important;
    }

    button {
      .p-button-label {
        color: white;
      }
    }
  }

  .stacking {
    .info {
      color: white;
    }

    .content {
      color: #C0C0C0 !important;
    }

  }

  .ico-card {
    .sub-card, .height-card, .price-ico-card, .basic-card-ico {
      background: $dark-bg-subcard !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    }

    .content-card .section .ico {

      background: #004D4D !important;
    }

    .sub-card, .height-card {
      .label {
        color: #C0C0C0 !important;
      }

      .price-txt {
        color: white;
      }
    }
  }

  .profil-card {
    .summit {
      color: #C0C0C0 !important;
    }

    .input-sponsor {
      background: $dark-bg-input;

      span {
        color: white;
      }
    }

    .string-ligne {
      background: $dark-bg-subcard;
    }

    .summit-tokens .actions .prices .label, .sponsorship .actions .prices .label {
      color: #C0C0C0 !important;

    }
  }

  .heading {
    color: #fff !important;
  }

}

.kyc {

  .content {
    .link {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */


      color: #D60580;
      text-decoration: underline;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

a.pink-hypertext {
  color: #E043A0 !important;
}


.prime-gradient-button {
  background: linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%) !important;
}

.pink-button {
  background: #D60580 !important;
}

.pink-outline {

  &.p-button-outlined {
    color: #D60580;

    &:hover {
      color: #E043A0 !important;
      background: transparent !important;
      transition: .3s;
    }

    .p-button-label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: #E043A0 !important;
    }
  }
}

//.dashboard {
//  .gradient-button {
//    border-radius: 5px !important;
//
//  }
//
//  .p-button-label {
//    font-size: 12px !important;
//  }
//}

button {
  border-radius: 5px;

  .p-button-label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 0.2px;
  }

}

.summitModal {

  .close {
    &:focus {
      border-color: #D60580 !important;
      outline: #D60580 !important;
      box-shadow: 0 0 0 2px #D60580 !important;
    }
  }
}


.p-button {
  &.close {
    &:hover {
      background: transparent !important;

      .pi {
        color: black;
      }
    }
  }

  padding-top: 11px !important;
  padding-bottom: 11px !important;

  &.outline-button {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  &:focus {
    //box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #D60580, 0 1px 2px 0 black;
    box-shadow: unset !important;
  }


  &:hover {
    background: transparent;
    transition: .3s;

  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    background: #A0AEC0;
  }

  &:enabled:active {
    background: transparent;

  }

  &:hover {
    background: transparent;
  }

  .p-button-label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
  }
}

.dashboard {
  .p-button {
    .p-button-label {
      font-size: 12px !important;
    }
  }
}

.p-ripple.purple .p-ink {
  background: rgba(#D60580, .4);
}


input {
  &:enabled:hover, &:enabled:focus {
    border-color: #D60580 !important;
  }
}

.outline-button {
  cursor: pointer;
  background: transparent;

  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.4px;
  /* Others/White */

  color: $white;


  transition: .3s;
  border: 1px solid #D60580;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    box-shadow: unset !important;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    background: #A0AEC0;
  }

  &:enabled:active {
    background: transparent;

  }

  .p-button-label {
    color: #D60580;
  }

  &:hover {
    background: #D60580 !important;

    .p-button-label {
      color: white !important;
    }

    border-color: #D60580 !important;
  }
}

.gradient-button {

  border: none;
  cursor: pointer;
  background: linear-gradient(45deg, #D60580, #00FFFF);
  border-radius: 5px;


  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.4px;
  /* Others/White */

  color: $white;


  transition: .3s;

  &:focus {
    //box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #D60580, 0 1px 2px 0 black;
    box-shadow: unset !important;
  }

  &:hover {
    //background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);
    background: linear-gradient(45deg, #D60580, #00FFFF);
    filter: brightness(1.05);

    transition: .3s;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    background: #A0AEC0 !important;

    &:hover {
      background: #A0AEC0 !important;
    }
  }


  &:enabled:active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(45deg, #D60580 1.02%, #00FFFF 97.39%);

  }

  &:hover {
    background: linear-gradient(71deg, var(--main-background-color-gradient-start) 15%, var(--main-background-color-gradient-end) 83%) !important;
  }

  .p-button-label {
    color: $white;
  }
}


app-lang {
  position: relative;

  .lang-container {
    position: absolute;
    z-index: 2000;
    top: 35px;
    left: -70px;

    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 141px;
    padding: 22px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.dashboard {

  &.research {

  }

  app-lang {
    .lang-container {
      position: fixed;
      z-index: 2000;
      bottom: 110px;
      top: unset;
      left: 90px;

      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      width: 141px;
      padding: 22px 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    @media screen and (max-width: 525px) {
      position: fixed;

      top: -100px;
      left: 90px;
      right: 0;
      height: 100%;
      width: 100%;
      display: flex;
      z-index: 20000;
    }
    justify-content: flex-start;
    align-items: flex-end;

  }
}

//.icon-menu {
//  position: relative;

//app-lang {
//  position: absolute;
//  top: 60px;
//  right: 90px;
//
//}
//}


@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.badge-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0px;
  width: 100%;
  /* body/medium/medium */

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-align: center;
  border-radius: 8px;
  letter-spacing: 0.2px;

  &.actif {
    background: rgba(133, 210, 80, 0.08);
    color: rgba(133, 210, 80, 0.7)
  }

  &.no-actif {
    background: rgba(128, 6, 35, 0.08);
    color: rgba(128, 6, 35, 0.7)
  }
}


.transactionRow {
  background: transparent !important;

  td {
    padding: 24px 1rem;
    //padding: unset !important;
  }


  .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Greyscale / 900 */
    margin: 0;
    color: #1A202C;
  }

  .sub {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Greyscale/600 */

    color: #718096;
  }

  .basic-txt {
    font-family: 'Roboto', sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Greyscale / 900 */

    color: #1A202C;
  }

  .date {
    display: flex;
    flex-direction: column;

    .date-txt {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      letter-spacing: 0.2px;

      /* Greyscale / 900 */

      color: #1A202C;
    }

    .time {
      /* body/medium/medium */
      font-family: 'Roboto', sans-serif;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: center;
      letter-spacing: 0.2px;

      /* Greyscale/600 */

      color: #718096;
    }
  }
}

p-card .p-card {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
}

.p-dialog-content {
  &::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;

  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.fireworks-ctn {
  width: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  z-index: 20000;
  bottom: 0;
  height: 100vh;
  pointer-events: none;
  top: -100%;
}

.list-item {
  display: list-item;
}

@keyframes firework {
  0% {
    transform: translate(var(--x), var(--initialY));
    width: var(--initialSize);
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  100% {
    width: var(--finalSize);
    opacity: 0;
  }
}


.firework,
.firework::before,
.firework::after {
  --initialSize: 0.5vmin;
  --finalSize: 45vmin;
  --particleSize: 0.2vmin;
  --color1: yellow;
  --color2: khaki;
  --color3: white;
  --color4: lime;
  --color5: gold;
  --color6: mediumseagreen;
  --y: -30vmin;
  --x: -50%;
  --initialY: 60vmin;
  content: "";
  animation: firework 2s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background: /*
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 100%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
    */

    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
      /* bottom right */
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
      /* bottom left */
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
      /* top left */
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
      /* top right */
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;
}

.firework::before {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  /*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
  transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
  /*   animation: fireworkPseudo 2s infinite; */
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  /*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
  /*   animation: fireworkPseudo 2s infinite; */
}

.firework:nth-child(2) {
  --x: 50vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
  --color1: pink;
  --color2: violet;
  --color3: fuchsia;
  --color4: orchid;
  --color5: plum;
  --color6: lavender;
  --finalSize: 40vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.25s;
}

.firework:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
  --color1: cyan;
  --color2: lightcyan;
  --color3: lightblue;
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 35vmin;
  left: 70%;
  top: 80%;
  animation-delay: -0.3s;
}


#canvas {
  image-rendering: crisp-edges;
  pointer-events: none;
}

.wheels {
  position: relative;

  img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
}

.p-fileupload .p-fileupload-content {
  background: transparent;
  padding: 0;
  border: none;

  p-progressbar {
    display: none;
  }
}

.code-input-container {
  width: 370px;
  margin: auto;


  code-input {
    --item-spacing: 10px !important;
    //--item-height: 3em !important;
    //--item-border: none !important;
    //--item-border-bottom: 2px solid #dddddd !important;
    //--item-border-has-value: none !important;
    //--item-border-bottom-has-value: 2px solid #888888 !important;
    //--item-border-focused: none !important;
    //--item-border-bottom-focused: 2px solid #809070 !important;
    //--item-shadow-focused: none !important;
    //--item-border-radius: 0px !important;


    input {
      width: 50px !important;
      height: 50px !important;
    }
  }

  @media screen and (max-width: 525px) {
    width: 100%;
    code-input {
      span {
        padding-right: 1px !important;
      }

      input {
        width: 40px !important;
        height: 45px !important;
      }
    }
  }
}


.unread-dot {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;

  border-radius: 50%;
  color: white !important;
  background: linear-gradient(
      71.27deg,
      #d60580 1.02%,
      #00ffff 97.39%,
      #00ffff 97.39%
  );

  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;

  -webkit-text-fill-color: white !important;
  background-clip: initial !important;

  @media screen and (max-width: 992px) {
    display: flex !important;
  }
}

